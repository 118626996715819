.check-result {
	width: 100%;
	padding: 32px 32px 64px;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: var(--color-blue-bg);
	border-radius: 8px;

	@include mobile-xl {
		padding: 24px 16px 32px;
	}
}

.check-result__back {
	@include button-reset;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-start;
	cursor: pointer;
	line-height: 20px;
	color: var(--color-blue);
	transition: opacity $transition-props;

	svg {
		margin-right: 10px;
	}

	@include hover {
		opacity: 0.5;
	}

	@include mobile-xl {
		margin-left: 8px;
		margin-bottom: 32px;
	}
}

.check-result__icon {
	width: 64px;
	height: 64px;
	margin-bottom: 24px;
}

.check-result__title {
	@include font('VW Head');
	margin: 0;
	margin-bottom: 16px;
	max-width: 763px;

	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
}

.check-result__text {
	margin: 0;
	margin-bottom: 48px;
	max-width: 864px;
	font-size: 16px;
	line-height: 28px;
	text-align: center;

	a {
		font-weight: 700;
		color: var(--color-black);
	}
}

.check-result__buttons {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
}

.check-result__btn {
	display: flex;
	width: 100%;
	max-width: 260px;
	margin-right: 24px;

	&:last-of-type {
		margin-right: 0;
	}

	@include mobile-xl {
		max-width: unset;
		width: 100%;
		margin-right: 0;
		margin-bottom: 24px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
