@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}
