@font-face {
  font-family: "VW Head";
  font-weight: 300; //light
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/vwheadoffice-light.woff2") format("woff2");
}

@font-face {
  font-family: "VW Head";
  font-weight: 400; //regular
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/vwheadoffice-regular.woff2") format("woff2");
}

@font-face {
  font-family: "VW Head";
  font-weight: 700; //bold
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/vwheadoffice-bold.woff2") format("woff2");
}

@font-face {
  font-family: "VW Text";
  font-weight: 400; //normal
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/vwtextoffice-regular.woff2") format("woff2");
}

@font-face {
  font-family: "VW Text";
  font-weight: 700; //bold
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/vwtextoffice-bold.woff2") format("woff2");
}
