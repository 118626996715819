@mixin desktop-max {
	@media (max-width: $width-max) {
		@content;
	}
}

@mixin container-width {
	@media (max-width: $width-container) {
		@content;
	}
}

@mixin desktop-min {
	@media (max-width: $width-desktop-min - 1px) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: $width-laptop - 1px) {
		@content;
	}
}

@mixin mobile-xl {
	@media (max-width: $width-tablet - 1) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $width-mobile-l - 1px) {
		@content;
	}
}

@mixin mobile-s {
	@media (max-width: $width-min) {
		@content;
	}
}

@mixin retina {
	@media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
		@content;
	}
}

@mixin list-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin button-reset {
	padding: 0;
	border: none;
	color: inherit;
	background-color: transparent;
	cursor: pointer;
}

@mixin text-main {
	font-size: $font-base;
	line-height: $font-base-line-height;
}

@mixin visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
	clip: rect(0 0 0 0);
	overflow: hidden;
}

@mixin font($font) {
	font-family: $font, 'Arial', sans-serif;
}

@mixin label {
}

@mixin form-input {
	width: 100%;
	padding: 15px;
	border: 1px solid $dashed-border;
	border-radius: 6px;
	line-height: inherit;
	background: none;
	background-color: $white;
}

@mixin textarea {
	resize: none;
	overflow: hidden;
	background: none;
}

@mixin hover {
	@media (hover: hover) {
		&:hover {
			@content;
		}
	}
}

@mixin active {
	@media (hover: hover) {
		&:active {
			@content;
		}
	}
}
