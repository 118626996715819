.service-check {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.service-check__title {
	@include font('VW Head');
	margin: 0;
	margin-bottom: 16px;
	max-width: 780px;
	font-weight: 700;
	font-size: 60px;
	line-height: 68px;
	text-align: center;

	@include tablet {
		font-size: 52px;
		line-height: 56px;
	}

	@include mobile {
		font-size: 32px;
		line-height: 36px;
	}
}

.service-check__text {
	margin: 0;
	margin-bottom: 64px;
	line-height: 20px;
	text-align: center;

	@include mobile {
		margin-bottom: 40px;
	}
}
