body {
	font-family: $font-family;
	font-weight: 400;
	font-size: $font-size;
}

:root {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*,
*::before,
*::after {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
	outline: none;
}
