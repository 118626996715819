.button {
	@include button-reset;
	padding: 10px 30px;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;

	background-color: var(--color-blue);
	border: 2px solid var(--color-blue);
	border-radius: 24px;
	color: var(--color-white);
	font-size: 16px;
	line-height: 28px;
	text-decoration: none;
	transition: background-color $transition-props, color $transition-props;

	@include hover {
		background-color: transparent;
		color: var(--color-blue);
	}
	
	&--transparent {
		background-color: transparent;
		color: var(--color-blue);

		@include hover {
			background-color: var(--color-blue);
			color: var(--color-white);
		}
	}


	&[disabled] {
		background-color: var(--color-light-gray);
		border-color: var(--color-light-gray);
		pointer-events: none;
	}


}
