.event-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.event-form__field {
	width: 100%;
	max-width: 380px;
	flex-grow: 1;
	position: relative;
	padding-top: var(--form-field-padding-top);
	padding-bottom: 20px;
	margin-bottom: 46px;
	display: flex;
	justify-content: center;
}

.event-form__input {
	width: 100%;
	padding: 6px 0;
	border: none;
	line-height: 1.1;
	border-bottom: 1px solid var(--color-gray-2);

	&:focus {
		outline: none;
		border-color: var(--color-border);
	}

	&.invalid {
		border-color: var(--color-error);
	}
}

.event-form__label {
	position: absolute;
	left: 0;
	top: var(--form-field-padding-top);
	color: var(	--color-blue);
	font-size: 16px;
	line-height: 24px;
	pointer-events: none;
	transition: all $transition-props;

	&--transitioned {
		font-size: 12px;
		top: 0;
	}
}

.event-form__submit {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.event-form__btn {
	width: 100%;
	max-width: 380px;
}

.event-form__error {
	margin: 0;
	color: var(--color-error);
	position: absolute;
	bottom: 0;
	left: 0;

	font-size: 14px;
	line-height: 18px;
}
