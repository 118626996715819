.container {
	max-width: $width-container;
	min-width: $width-min;
	margin: 0 auto;
	padding-left: $desktop-padding;
	padding-right: $desktop-padding;

	@include tablet {
		padding-left: $tablet-padding;
		padding-right: $tablet-padding;
	}

	@include mobile-xl {
		padding-left: $mobile-padding;
		padding-right: $mobile-padding;
	}
}
