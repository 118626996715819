:root {
	--color-black: #000000;
	--color-blue: #001e50;
	--color-white: #ffffff;
	--color-border: #333333;
	--color-dark-gray: #333333;
	--color-gray-bg: #f3f3f3;
	--color-light-gray: #999999;
	--color-gray-1: #e5e5e5;
	--color-gray-2: #dfe4e8;
	--color-blue-bg: #f3f8fa;
	--color-error: #ff0000;
	--color-red-text: #f50537;

	--form-field-padding-top: 19px;
}

$width-max: 1440px;
$width-container: 1186px;
$width-desktop-min: 1280px;
$width-laptop: 1024px;
$width-tablet: 768px;
$width-mobile-l: 560px;
$width-mobile-s: 390px;
$width-min: 320px;

$desktop-padding: 40px;
$tablet-padding: 32px;
$mobile-padding: 12px;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

$font-family: 'VW Text', 'Arial', sans-serif;
$font-size: 16px;

//TRANSITIONS
//! example: transition: blur $transition-props, opacity $transition-props;
$transition-duration: 0.2s;
$transition-timing-function: cubic-bezier(0.79, 0.14, 0.15, 0.86);
$transition-props: $transition-duration $transition-timing-function;
