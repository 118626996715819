.loader {
	width: 25px;
	height: 25px;
	margin-right: 8px;

	animation: rotate 2s linear infinite;
	transform-origin: center center;
}

.loader__circle {
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -10;
	animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	stroke-linecap: round;
}
